import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import TopNav from '../components/TopNav'
import Add from '../components/Add'
import ServicesMain from '../components/ServicesMain'

function Services() {
  return (
    <><TopNav />
<Navbar />
<ServicesMain />
<Add />
<Footer /></>  )
}

export default Services