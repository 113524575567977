import React from 'react'
import { FcApproval } from 'react-icons/fc';

function Additional() {
  return (
    <div>
        <div className='row'>
          <h1 className='text-center'>We specialized in</h1>
        </div><div className='row text-center mt-5 mb-5'>
        <div className='col llol'><h4 className='mb-5'><img src='./p.png' alt='dazzling event' className='lkm'/><br></br><br></br>Maternity Photoshoot</h4></div> 
         <div className='col llol'><h4 className='mb-5'><img src='./child.png' alt='dazzling event' className='baby'/><br></br><br></br>Baby Photoshoot</h4></div>
         <div className='col llol'><h4 className='mb-5'><img src='./model.png' alt='dazzling event' className='model'/><br></br><br></br>Modeling Photoshoot </h4></div>        </div>
     <div className='row'>
     <div className='col-3'>
    <h5 className='cine mb-3'>Photography And Cinematography</h5> <p className='marg'> <FcApproval /> Basic Photography </p>
 <p className='marg'> <FcApproval />Candid Photography</p><p className='marg'> <FcApproval />HD Videography</p>
    <p className='marg'> <FcApproval />Candid Videography</p><p className='marg'> <FcApproval />Drone Videography</p><p className='marg'> <FcApproval />Canvera Photo Album</p>
     </div>
     <div className='col-3'>    <h5 className='min mb-3'>Furnitures For Rent</h5> 
     <p className='marg'> <FcApproval /> Sofa </p><p className='marg'> <FcApproval />Stage Platform With Carpet </p>
    <p className='marg'> <FcApproval />Chairs With Covers </p><p className='marg'> <FcApproval />Circular Tables With Covers</p><p className='marg'> <FcApproval />Food Tables With Covers</p>
    <p className='marg'> <FcApproval />Shamiana Tent (Top Cover)</p>
       </div>
     <div className='col-3'>    <h5 className='min mb-3'>Entertainment Services
</h5> 
<p className='marg'> <FcApproval /> Music Speaker System  </p><p className='marg'> <FcApproval />Games Organizer / Anchor </p>
    <p className='marg'> <FcApproval />Magician </p><p className='marg'> <FcApproval />Cold Fire Works</p><p className='marg'> <FcApproval />Smoke Fog Machine</p>
    <p className='marg'> <FcApproval />Tattoo Painting Artist</p><p className='marg'> <FcApproval />Caricature Drawing Artist</p>
    




    <p className='marg d-sm-none'> <FcApproval />Balloon Shooting </p><p className='marg d-sm-none'> <FcApproval />Live Cartoon Mascot</p>
    <p className='marg d-sm-none'> <FcApproval />Balloon Craft/ Modelling</p><p className='marg d-sm-none'> <FcApproval />Cotton Candy </p><p className='marg d-sm-none'> <FcApproval />Chocolate Fountain</p>
    <p className='marg d-sm-none'> <FcApproval />Make Up Artist</p>
</div><div className='col-3 margtop clla '>
<p className='marg'> <FcApproval />Balloon Shooting </p><p className='marg'> <FcApproval />Live Cartoon Mascot</p>
    <p className='marg'> <FcApproval />Balloon Craft/ Modelling</p><p className='marg'> <FcApproval />Cotton Candy </p><p className='marg'> <FcApproval />Chocolate Fountain</p>
    <p className='marg'> <FcApproval />Make Up Artist</p>
</div>
        </div>   
    </div>
  )
}

export default Additional