import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import TopNav from '../components/TopNav'
import Gal from '../components/Gal'

function Gallery() {
  return (
    <><TopNav />
<Navbar /><Gal /> <Footer /></>  )
}

export default Gallery